import { MutationTree } from 'vuex';
import CommonState from './commonState';
const mutations: MutationTree<CommonState> = {
  setAnalyticsConsent(state: CommonState, analyticsConsent: boolean) {
    state.analyticsConsent = analyticsConsent;
  },
  setLanguage(state: CommonState, language: string) {
    state.language = language;
  },
};

export default mutations;
