








import i18n from './i18n';
import { Component, Vue } from 'vue-property-decorator';
import { CommonGetters } from './store/modules/common/enums';
import Spinner from '@/components/common/Spinner.vue';
import Toaster from '@/components/toaster/Toaster.vue';
import './style/app.scss';

@Component({
  components: {
    'ck-toaster': Toaster,
    'ck-spinner': Spinner,
  },
})
export default class App extends Vue {
  public mounted(): void {
    this.setLanguage();
  }

  private setLanguage() {
    const storedLanguage = this.$store.getters[CommonGetters.Language];
    if (storedLanguage) {
      i18n.locale = storedLanguage;
    } else {
      const navigatorLanguage = window.navigator.language || 'en';
      const navigatorCode = navigatorLanguage.split('-');
      i18n.locale = navigatorCode[0];
    }
  }
}
