import { ActionContext, ActionTree } from 'vuex';
import { mediaService } from '@/services/medias/media.service';
import { MediasMutations } from './enums';
import MediasState from './mediasState';
import RootState from '@/store/rootState';
import { MediaStatus } from '@/models/medias/mediaStatus';

const actions: ActionTree<MediasState, RootState> = {
  getMedias: (store: ActionContext<MediasState, RootState>, init: boolean) => {
    return new Promise((resolve, reject) => {
      mediaService
        .getMedias()
        .then((response) => {
          store.commit(MediasMutations.SetMedias, response);
          if (init) {
            if (response.length > 0) {
              store.commit(
                MediasMutations.SetMerchantId,
                response[0].merchantId,
              );
            } else {
              store.commit(MediasMutations.SetMerchantId, '');
              store.commit(MediasMutations.SetSelectedMediaId, '');
            }
          }
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  setMerchantId: (
    store: ActionContext<MediasState, RootState>,
    merchantId: string,
  ) => {
    return new Promise<void>((resolve) => {
      store.commit(MediasMutations.SetMerchantId, merchantId);
      resolve();
    });
  },
  setMediaStatusToAssociate: (
    store: ActionContext<MediasState, RootState>,
    mediaStatus: MediaStatus,
  ) => {
    return new Promise<void>((resolve) => {
      store.commit(MediasMutations.SetMediaStatusToAssociate, mediaStatus);
      resolve();
    });
  },
  setSelectedMediaId: (
    store: ActionContext<MediasState, RootState>,
    mediaId: string,
  ) => {
    return new Promise<void>((resolve) => {
      store.commit(MediasMutations.SetSelectedMediaId, mediaId);
      resolve();
    });
  },
  updateAlias: (
    store: ActionContext<MediasState, RootState>,
    payload: { alias: string; mediaSerial: string },
  ) => {
    store.state.medias
      .filter((m) => {
        return m.serial === payload.mediaSerial;
      })
      .forEach((m) => {
        m.alias = payload.alias;
      });
  },
  clear: (store: ActionContext<MediasState, RootState>) => {
    store.commit(MediasMutations.Clear);
  },
};

export default actions;
