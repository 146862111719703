import { GetterTree } from 'vuex';
import WebPaymentsState from './webPaymentsState';
import RootState from '@/store/rootState';

const getters: GetterTree<WebPaymentsState, RootState> = {
  getServices: (webPaymentsState: WebPaymentsState) =>
    webPaymentsState.services,
  getConfigs: (webPaymentsState: WebPaymentsState) => webPaymentsState.configs,
  getChargeAmount: (webPaymentsState: WebPaymentsState) =>
    webPaymentsState.chargeAmount,
  getSelectedServiceType: (webPaymentsState: WebPaymentsState) =>
    webPaymentsState.selectedServiceType,
  getNexiPayload: (webPaymentsState: WebPaymentsState) =>
    webPaymentsState.nexiPayload,
  getNexiTries: (webPaymentsState: WebPaymentsState) =>
    webPaymentsState.nexiTries,
};

export default getters;
