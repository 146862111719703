export enum AuthMutations {
  AuthRequest = 'authRequest',
  Success = 'authSuccess',
  Error = 'authError',
  Logout = 'authLogout',
  AwaitingB2CResponse = 'authAwaitingB2CResponse',
}

export enum AuthActions {
  Login = 'auth/login',
  Logout = 'auth/logout',
  CheckQR = 'auth/QRLogin',
  CheckMobileHash = 'auth/MobileAppRegistration',
  SetAwaitingB2CResponse = 'auth/setAwaitingB2CResponse',
}

export enum AuthGetters {
  IsAuthenticated = 'auth/isAuthenticated',
  AuthStatus = 'auth/authStatus',
  GetCustomerInfo = 'auth/getCustomerInfo',
  AwaitingB2CResponse = 'auth/awaitingB2CResponse',
}
