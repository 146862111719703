import { MerchantContact } from '@/models/config/merchantContact';

export class MerchantConfig {
  public merchantId: string | undefined = undefined;
  public companyName: string | undefined = undefined;
  public timeZone: string | undefined = undefined;
  public currency: string | undefined = undefined;
  public legalCompanyName: string | undefined = undefined;
  public legalAddress: string | undefined = undefined;
  public legalEmail: string | undefined = undefined;
  public legalVatCode: string | undefined = undefined;
  public services: string[] | undefined = [];
  public logoUrl: string | undefined = undefined;
  public color: string | undefined = undefined;
  public ingredientsUrl: string | undefined = undefined;
  public websiteUrl: string | undefined = undefined;
  public contacts: MerchantContact[] | undefined = [];
  public mediaHasCustomer = false;
}
