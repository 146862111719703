import { MerchantConfig } from '@/models/config/merchantConfig';
import { MutationTree } from 'vuex';
import MerchantConfigState from './merchantConfigState';

const mutations: MutationTree<MerchantConfigState> = {
  merchantConfigSetMerchantConfig: (
    merchantConfigState: MerchantConfigState,
    merchantConfig: MerchantConfig,
  ) => {
    merchantConfigState.merchantConfig = merchantConfig;
  },
  clear: (merchantConfigState: MerchantConfigState) => {
    merchantConfigState.merchantConfig = {} as MerchantConfig;
  },
};

export default mutations;
