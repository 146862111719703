import ToasterData from '@/components/toaster/ToasterData.model';
import { ActionContext, ActionTree } from 'vuex';
import { RootMutations } from './enums';
import RootState from './rootState';

const actions: ActionTree<RootState, any> = {
  showToaster: (
    store: ActionContext<RootState, any>,
    payload: { toaster: ToasterData },
  ) => {
    payload.toaster.id = Date.now() + Math.floor(Math.random() * 10);
    store.commit(RootMutations.ShowToaster, payload.toaster);

    window.setTimeout(() => {
      store.commit(RootMutations.RemoveToaster, payload.toaster.id);
    }, 5000);
  },
  showEternalToaster: (
    store: ActionContext<RootState, any>,
    payload: { toaster: ToasterData },
  ) => {
    payload.toaster.id = Date.now() + Math.floor(Math.random() * 10);
    store.commit(RootMutations.ShowToaster, payload.toaster);
  },
  removeToasters: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.RemoveToasters);
  },
  removeToaster: (
    store: ActionContext<RootState, any>,
    payload: { toasterId: number },
  ) => {
    store.commit(RootMutations.RemoveToaster, payload.toasterId);
  },
  showSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.ShowSpinner);
  },
  removeSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.RemoveSpinner);
  },
};

export default actions;
