import { HttpErrors } from '@/models/common/constants';
import { eventHub } from '@/store/consts';
import axios from 'axios';
import { Promise } from 'es6-promise';

export const refreshTokenInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      eventHub.$emit('after-response', response.config.url);
      // Return a successful response back to the calling service
      return response;
    },
    (error) => {
      eventHub.$emit('response-error', error);
      // Return any error which is not due to authentication back to the calling service
      if (error.response?.status !== HttpErrors.Unauthorized) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    },
  );
};
