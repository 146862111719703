



















import { Component, Vue } from 'vue-property-decorator';
import ToasterData, { ToasterTypes } from './ToasterData.model';
import { RootGetters } from '@/store/enums';

@Component
export default class Toaster extends Vue {
  public toasterData: ToasterData = {} as ToasterData;

  public getStyles(type: ToasterTypes): string {
    const styles: string[] = [`toaster__icon--${type}`];

    if (type === 'warning') {
      styles.push('toaster--warning');
    } else if (type === 'error') {
      styles.push('toaster--error');
    } else {
      styles.push('toaster--success');
    }

    return styles.join(' ');
  }

  get toasters(): ToasterData[] {
    const toasters = this.$store.getters[RootGetters.Toasters] as ToasterData[];
    return toasters;
  }

  public remove(id: number): void {
    this.$toaster.removeToaster(id);
  }
}
