import { CombinedVueInstance } from 'vue/types/vue';
import Vue from 'vue';

export const appApplicationErrorsInterceptor = (
  app: CombinedVueInstance<Vue, object, object, object, Record<never, any>>,
) => {
  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    app.$appInsights.trackException(err, vm, info);
  };
};
