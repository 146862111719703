import { msalPluginInstance } from '@/plugin/MsalPlugin';
import { eventHub } from '@/store/consts';
import axios from 'axios';
import { Promise } from 'es6-promise';

export const headerBearerTokenInterceptor = () => {
  axios.interceptors.request.use(
    async (config: any) => {
      eventHub.$emit('before-request', config.url);

      config = await msalPluginInstance.acquireToken().then((authToken) => {
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      });

      // const authToken = store.getters[AuthGetters.AuthToken];
      // config.headers['Content-Type'] = 'application/json';

      // if (authToken) {
      // config.headers.Authorization = `Bearer ${authToken}`;
      // }
      return config;
    },
    (err: any) => {
      eventHub.$emit('request-error', err);

      return Promise.reject(err);
    },
  );
};
