import { WebPaymentService } from '@/models/web-payments/webPaymentService';
import { WebPaymentConfig } from '@/models/web-payments/webPaymentConfig';
import WebPaymentsState from './webPaymentsState';
import { MutationTree } from 'vuex';
import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { WebPaymentServiceType } from '@/models/web-payments/webPaymentServiceType';

const mutations: MutationTree<WebPaymentsState> = {
  webPaymentsSetServices: (
    webPaymentsState: WebPaymentsState,
    services: WebPaymentService[],
  ) => {
    webPaymentsState.services = services;
  },
  webPaymentsSetConfigs: (
    webPaymentsState: WebPaymentsState,
    configs: WebPaymentConfig[],
  ) => {
    webPaymentsState.configs = configs;
  },
  webPaymentsSetChargeAmount: (
    webPaymentsState: WebPaymentsState,
    chargeAmount: number,
  ) => {
    webPaymentsState.chargeAmount = chargeAmount;
  },
  webPaymentsSetSelectedServiceType: (
    webPaymentsState: WebPaymentsState,
    serviceType: WebPaymentServiceType,
  ) => {
    webPaymentsState.selectedServiceType = serviceType;
  },
  webPaymentsSetNexiPayload: (
    webPaymentsState: WebPaymentsState,
    payload: NexiPayload,
  ) => {
    webPaymentsState.nexiPayload = payload;
  },
  webPaymentsIncrementNexiTries: (webPaymentsState: WebPaymentsState) => {
    webPaymentsState.nexiTries = webPaymentsState.nexiTries + 1;
  },
  webPaymentsResetNexiTries: (webPaymentsState: WebPaymentsState) => {
    webPaymentsState.nexiTries = 0;
  },
  clear: (webPaymentsState: WebPaymentsState) => {
    webPaymentsState.services = [] as WebPaymentService[];
    webPaymentsState.configs = [] as WebPaymentConfig[];
    webPaymentsState.chargeAmount = 0;
    webPaymentsState.selectedServiceType = {} as WebPaymentServiceType;
    webPaymentsState.nexiPayload = {} as NexiPayload;
    webPaymentsState.nexiTries = 0;
  },
};

export default mutations;
