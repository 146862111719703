import axios from 'axios';
import store from '@/store';
import { CombinedVueInstance } from 'vue/types/vue';
import { CommonGetters } from '@/store/modules/common/enums';
import { Promise } from 'es6-promise';

export const appInsightRequestErrorsInterceptor = (
  app: CombinedVueInstance<Vue, object, object, object, Record<never, any>>,
) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (store.getters[CommonGetters.AnalyticsConsent] as boolean)
        app.$appInsights.trackException(error);
      return new Promise((resolve, reject) => {
        reject(error);
      });
    },
  );
};
