import actions from '@/store/modules/web-payments/actions';
import getters from '@/store/modules/web-payments/getters';
import mutations from '@/store/modules/web-payments/mutations';
import state from '@/store/modules/web-payments/state';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
