import MediasState from './mediasState';
import { Media } from '@/models/medias/media';
import { MutationTree } from 'vuex';
import { MediaStatus } from '@/models/medias/mediaStatus';

const mutations: MutationTree<MediasState> = {
  mediasSetMedias: (mediasState: MediasState, medias: Media[]) => {
    mediasState.medias = medias;
  },
  mediasSetMerchantId: (mediasState: MediasState, merchantId: string) => {
    mediasState.merchantId = merchantId;
  },
  mediasSetMediaStatusToAssociate: (
    mediasState: MediasState,
    mediaStatus: MediaStatus,
  ) => {
    mediasState.mediaStatusToAssociate = mediaStatus;
  },
  mediasSetSelectedMediaId: (mediasState: MediasState, mediaId: string) => {
    mediasState.selectedMediaId = mediaId;
  },
  clear: (mediasState: MediasState) => {
    mediasState.medias = [] as Media[];
    mediasState.merchantId = '';
    mediasState.mediaStatusToAssociate = null;
    mediasState.selectedMediaId = '';
  },
};

export default mutations;
