import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantPrivacy } from '@/models/config/merchantPrivacy';
import BaseService from '@/services/base/base.service';
import axios from 'axios';

class ConfigService extends BaseService {
  public async getMerchantConfig(merchantId: string): Promise<MerchantConfig> {
    const response = await axios.get<MerchantConfig>(
      `${super.clientsApiBaseUrl()}/config/${merchantId}`,
    );
    return response.data;
  }

  public async getMerchantPrivacy(
    merchantId: string,
    lang: string,
  ): Promise<MerchantPrivacy> {
    const response = await axios.get<MerchantPrivacy>(
      `${super.clientsApiBaseUrl()}/config/${merchantId}/privacy?lang=${lang}`,
    );
    return response.data;
  }

  public async getByMediaHash(hash: string): Promise<MerchantConfig> {
    const response = await axios.get<MerchantConfig>(
      `${super.clientsApiBaseUrl()}/config/media/${hash}`,
    );
    return response.data;
  }

  public async getByMobileHash(hash: string): Promise<MerchantConfig> {
    const response = await axios.get<MerchantConfig>(
      `${super.clientsApiBaseUrl()}/config/mobile/${hash}`,
    );
    return response.data;
  }
}

export const configService = new ConfigService();
