import { configService } from '@/services/config/config.service';
import { customerService } from '@/services/customers/customer.service';
import AuthState from '@/store/modules/auth/authState';
import { AuthMutations } from '@/store/modules/auth/enums';
import RootState from '@/store/rootState';
import { ActionContext, ActionTree } from 'vuex';

const actions: ActionTree<AuthState, RootState> = {
  login: async (store: ActionContext<AuthState, RootState>) => {
    return new Promise((resolve, reject) => {
      store.commit(AuthMutations.AuthRequest);
      customerService.loadProfile().then((result: any) => {
        store.commit(
          AuthMutations.Success,
          Object.assign({}, { customerInfo: result.data }),
        );
      });
      //   resolve(result);
      // })
      // .catch((errors: any) => {
      //   store.commit(AuthMutations.Error, errors);
      //   reject(errors);
      // });
    });
  },

  logout: async (store: ActionContext<AuthState, RootState>) => {
    store.commit(AuthMutations.Logout);
  },

  QRLogin: async (
    store: ActionContext<AuthState, RootState>,
    qrCode: string,
  ) => {
    return new Promise<void>((resolve, reject) => {
      configService
        .getByMediaHash(qrCode)
        .then(async (result: any) => resolve(result))
        .catch((error: any) => {
          store.commit(AuthMutations.Error, error);
          reject(error);
        });
    });
  },

  MobileAppRegistration: async (
    store: ActionContext<AuthState, RootState>,
    hash: string,
  ) => {
    return new Promise<void>((resolve, reject) => {
      configService
        .getByMobileHash(hash)
        .then(async (result: any) => resolve(result))
        .catch((error: any) => {
          store.commit(AuthMutations.Error, error);
          reject(error);
        });
    });
  },

  setAwaitingB2CResponse: (
    store: ActionContext<AuthState, RootState>,
    payload: boolean,
  ) => {
    return new Promise<void>((resolve) => {
      store.commit(AuthMutations.AwaitingB2CResponse, payload);
      resolve();
    });
  },
};

export default actions;
