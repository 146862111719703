import BaseService from '@/services/base/base.service';
import axios from 'axios';

class MigrationService extends BaseService {
  public async getMigratedMerchants(merchantId: string): Promise<string[]> {
    const response = await axios.get<string[]>(
      `${super.clientsApiBaseUrl()}/migration/${merchantId}`,
    );
    return response.data;
  }
}

export const migrationService = new MigrationService();
