import { GetterTree } from 'vuex';
import MerchantMigrationState from './merchantMigrationState';
import RootState from '@/store/rootState';

const getters: GetterTree<MerchantMigrationState, RootState> = {
  getMigratedMerchants: (merchantMigrationState: MerchantMigrationState) =>
    merchantMigrationState.migratedMerchants,
};

export default getters;
