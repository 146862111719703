export enum MerchantConfigMutations {
  SetMerchantConfig = 'merchantConfigSetMerchantConfig',
  Clear = 'clear',
}

export enum MerchantConfigActions {
  GetMerchantConfig = 'merchantConfig/getMerchantConfig',
  SetMerchantConfig = 'merchantConfig/setMerchantConfig',
  Clear = 'merchantConfig/clear',
}

export enum MerchantConfigGetters {
  GetMerchantConfig = 'merchantConfig/getMerchantConfig',
}
