import MediasState from './mediasState';

const state: MediasState = {
  medias: [],
  merchantId: '',
  mediaStatusToAssociate: null,
  selectedMediaId: '',
};

export default state;
