export enum Routes {
  Home = 'home',
  Privacy = 'privacy',
  Cookies = 'cookies',
  BlogPost = 'blog-post',
  Customer = 'customer',
  PreCustomer = 'pre-customer',
  QRCheck = 'qr-check',
  MobileAppRegistration = 'mobileapp-registration',
}
