import { MutationTree } from 'vuex';
import MerchantMigrationState from './merchantMigrationState';

const mutations: MutationTree<MerchantMigrationState> = {
  merchantMigrationSetMigratedMerchants: (
    merchantMigrationState: MerchantMigrationState,
    migratedMerchants: string[],
  ) => {
    merchantMigrationState.migratedMerchants = migratedMerchants;
  },
  clear: (merchantMigrationState: MerchantMigrationState) => {
    merchantMigrationState.migratedMerchants = [];
  },
};

export default mutations;
