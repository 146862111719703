import { PrivacyConsents } from '@/models/customers/privacyConsents';
import BaseService from '@/services/base/base.service';
import CustomerInfo from '@/store/modules/auth/authState';
import axios from 'axios';

class CustomerService extends BaseService {
  public async loadProfile() {
    return await axios.get(`${super.clientsApiBaseUrl()}/customers/me`);
  }

  // TODO: review this page, size, also merchantid should be passed to the body of the request, not from query
  public loadUserPurchases(
    mediaId: string,
    merchantId: string,
    page = 1,
    size = 10,
  ) {
    const response = axios.get(
      `${super.clientsApiBaseUrl()}/medias/${mediaId}/transactions` +
        `?merchantId=${merchantId}&page=${page}&size=${size}`,
    );

    return response;
  }

  public async updateProfile(customerInfo: CustomerInfo) {
    return await axios.put(
      `${super.clientsApiBaseUrl()}/customers/me`,
      customerInfo,
    );
  }

  public async createProfile(userInfo: any) {
    return await axios.post(
      `${super.clientsApiBaseUrl()}/customers/register`,
      userInfo,
    );
  }

  public async deleteProfile() {
    return await axios.delete(`${super.clientsApiBaseUrl()}/customers/me`);
  }

  public async updatePrivacyConsents(
    merchantId: string,
    privacyInfo: Partial<PrivacyConsents>,
  ) {
    return await axios.put(
      `${super.clientsApiBaseUrl()}/customers/${merchantId}/privacy`,
      privacyInfo,
    );
  }

  public async getPrivacyConsents(
    merchantId: string,
  ): Promise<PrivacyConsents> {
    const response = await axios.get(
      `${super.clientsApiBaseUrl()}/customers/${merchantId}/privacy`,
    );

    return response.data;
  }
}

export const customerService = new CustomerService();
