import { ConfigurationSpa } from '@/models/common/configurationSpa';
import { ApiBaseUrl } from '@/models/common/constants';
import axios from 'axios';

class ConfigurationService {
  public async getConfig(): Promise<ConfigurationSpa> {
    const response = await axios.get<ConfigurationSpa>(
      `${ApiBaseUrl}/spa-configuration`,
    );
    return response.data;
  }
}

export const configurationService = new ConfigurationService();
