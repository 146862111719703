import { GetterTree } from 'vuex';
import RootState from '@/store/rootState';
import MerchantConfigState from './merchantConfigState';

const getters: GetterTree<MerchantConfigState, RootState> = {
  getMerchantConfig: (merchantConfigState: MerchantConfigState) =>
    merchantConfigState.merchantConfig,
};

export default getters;
