import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { WebPaymentServiceType } from '@/models/web-payments/webPaymentServiceType';
import WebPaymentsState from './webPaymentsState';

const state: WebPaymentsState = {
  services: [],
  configs: [],
  chargeAmount: 0,
  selectedServiceType: {} as WebPaymentServiceType,
  nexiPayload: new NexiPayload(),
  nexiTries: 0,
};

export default state;
