import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

export const messages = {
  de: require('./locale/de.json'),
  en: require('./locale/en.json'),
  es: require('./locale/es.json'),
  fr: require('./locale/fr.json'),
  it: require('./locale/it.json'),
  cs: require('./locale/cs.json'),
};

export enum Locales {
  EN = 'en',
  FR = 'fr',
  IT = 'it',
  DE = 'de',
  ES = 'es',
  CS = 'cs',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.ES, caption: 'Español' },
  { value: Locales.DE, caption: 'Deutsch' },
  { value: Locales.IT, caption: 'Italiano' },
  { value: Locales.FR, caption: 'Français' },
  { value: Locales.CS, caption: 'Český' },
];

const availableLanguages = Object.keys(messages);
const navigatorLanguage = window.navigator.language || 'en';
const navigatorCode = navigatorLanguage.split('-')[0];
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';
const locale =
  availableLanguages.indexOf(navigatorCode) >= 0
    ? navigatorCode
    : process.env.VUE_APP_I18N_LOCALE || 'en';

export default new VueI18n({
  locale,
  fallbackLocale,
  messages,
});
