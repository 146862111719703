import { RootActions } from '@/store/enums';
import { Store } from 'vuex';
import RootState from '@/store/rootState';
import ToasterData from '@/components/toaster/ToasterData.model';
import VueI18n from 'vue-i18n';

export class ToasterService {
  constructor(private store: Store<RootState>, private i18n: VueI18n) {}

  public showError(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showToaster({ type: 'error', message } as ToasterData);
  }

  public showSuccess(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showToaster({ type: 'success', message } as ToasterData);
  }

  public showWarning(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showToaster({ type: 'warning', message } as ToasterData);
  }

  public showEternalError(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showEternalToaster({ type: 'error', message } as ToasterData);
  }

  public showEternalSuccess(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showEternalToaster({ type: 'success', message } as ToasterData);
  }

  public showEternalWarning(message: string, translate?: boolean): void {
    message = translate ? this.i18n.t(message).toString() : message;
    this.showEternalToaster({ type: 'warning', message } as ToasterData);
  }

  public removeToaster(toasterId: number): void {
    this.store.dispatch(RootActions.RemoveToaster, { toasterId });
  }

  public removeToasters(): void {
    this.store.dispatch(RootActions.RemoveToasters);
  }

  private showToaster(toaster: ToasterData) {
    this.store.dispatch(RootActions.ShowToaster, { toaster });
  }

  private showEternalToaster(toaster: ToasterData) {
    this.store.dispatch(RootActions.ShowEternalToaster, { toaster });
  }
}
