import { msalPluginInstance } from '@/plugin/MsalPlugin';
import { Routes } from '@/router/routes';
import VueRouter from 'vue-router';

export const authMiddlewareRouter = (router: VueRouter) => {
  router.beforeEach((to: any, from: any, next: any) => {
    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
      const isAuth = msalPluginInstance.isUserAuthenticated();
      if (!isAuth) {
        next({ name: Routes.Home });
      }
    }
    next();
  });
};
