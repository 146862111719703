import i18n from '@/i18n';
import CommonState from '@/store/modules/common/commonState';
import RootState from '@/store/rootState';
import { ActionContext, ActionTree } from 'vuex';
import { CommonMutations } from './enums';

const actions: ActionTree<CommonState, RootState> = {
  setAnalyticsConsent: (
    store: ActionContext<CommonState, RootState>,
    analyticsConsent: boolean,
  ) => {
    store.commit(CommonMutations.SetAnalyticsConsent, analyticsConsent);
  },
  setLanguage: (
    store: ActionContext<CommonState, RootState>,
    language: string,
  ) => {
    i18n.locale = language;
    store.commit(CommonMutations.SetLanguage, language);
  },
};

export default actions;
