export enum MediasMutations {
  SetMedias = 'mediasSetMedias',
  SetMerchantId = 'mediasSetMerchantId',
  SetMediaStatusToAssociate = 'mediasSetMediaStatusToAssociate',
  SetSelectedMediaId = 'mediasSetSelectedMediaId',
  Clear = 'clear',
}

export enum MediasActions {
  GetMedias = 'medias/getMedias',
  SetMerchantId = 'medias/setMerchantId',
  SetMediaStatusToAssociate = 'medias/setMediaStatusToAssociate',
  SetSelectedMediaId = 'medias/setSelectedMediaId',
  UpdateAlias = 'medias/updateAlias',
  Clear = 'medias/clear',
}

export enum MediasGetters {
  GetMedias = 'medias/getMedias',
  GetMerchantId = 'medias/getMerchantId',
  GetMediaStatusToAssociate = 'medias/getMediaStatusToAssociate',
  GetSelectedMediaId = 'medias/getSelectedMediaId',
}
