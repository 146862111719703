import { msalPluginInstance } from '@/plugin/MsalPlugin';
import {
  default as AuthState,
  default as CustomerInfo,
} from '@/store/modules/auth/authState';
import RootState from '@/store/rootState';
import { GetterTree } from 'vuex';

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: () => {
    return msalPluginInstance.isUserAuthenticated();
  },
  authStatus: (authState: AuthState) => authState.status,
  authToken: () => {
    const token = msalPluginInstance.acquireToken().then((t) => {
      return t;
    });
    return token;
  },
  getCustomerInfo: (authState: AuthState): CustomerInfo | undefined =>
    authState.customerInfo,
  awaitingB2CResponse: (authState: AuthState) => authState.awaitingB2CResponse,
};

export default getters;
