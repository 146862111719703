export enum MerchantMigrationMutations {
  SetMigratedMerchants = 'merchantMigrationSetMigratedMerchants',
  Clear = 'clear',
}

export enum MerchantMigrationActions {
  GetMigratedMerchants = 'merchantMigration/getMigratedMerchants',
  Clear = 'merchantMigration/clear',
}

export enum MerchantMigrationGetters {
  GetMigratedMerchants = 'merchantMigration/getMigratedMerchants',
}
