import { MerchantConfig } from '@/models/config/merchantConfig';
import { configService } from '@/services/config/config.service';
import { MerchantConfigMutations } from '@/store/modules/merchant-config/enums';
import RootState from '@/store/rootState';
import { ActionContext, ActionTree } from 'vuex';
import MerchantConfigState from './merchantConfigState';

const actions: ActionTree<MerchantConfigState, RootState> = {
  getMerchantConfig: (
    store: ActionContext<MerchantConfigState, RootState>,
    merchantId: string,
  ) => {
    return new Promise((resolve, reject) => {
      configService
        .getMerchantConfig(merchantId)
        .then((response) => {
          store.commit(MerchantConfigMutations.SetMerchantConfig, response);
          resolve(response);
        })
        .catch((errors: any) => {
          store.commit(
            MerchantConfigMutations.SetMerchantConfig,
            new MerchantConfig(),
          );
          reject(errors);
        });
    });
  },
  setMerchantConfig: (
    store: ActionContext<MerchantConfigState, RootState>,
    merchantConfig: MerchantConfig,
  ) => {
    return new Promise<void>((resolve) => {
      store.commit(MerchantConfigMutations.SetMerchantConfig, merchantConfig);
      resolve();
    });
  },
  clear: (store: ActionContext<MerchantConfigState, RootState>) => {
    store.commit(MerchantConfigMutations.Clear);
  },
};

export default actions;
