import { WebPaymentConfig } from '@/models/web-payments/webPaymentConfig';
import { WebPaymentService } from '@/models/web-payments/webPaymentService';
import { WebPaymentOrder } from '@/models/web-payments/webPaymentOrder';
import BaseService from '@/services/base/base.service';
import axios from 'axios';
import { NexiPayload } from '@/models/web-payments/nexiPayload';

class WebPaymentApiService extends BaseService {
  public async getServices(): Promise<WebPaymentService[]> {
    const response = await axios.get<WebPaymentService[]>(
      `${super.clientsApiBaseUrl()}/webpaymentservices`,
    );
    return response.data;
  }

  public async getConfigs(merchantId: string): Promise<WebPaymentConfig[]> {
    const response = await axios.get<WebPaymentConfig[]>(
      `${super.clientsApiBaseUrl()}/webpaymentconfigs/${merchantId}`,
    );
    return response.data;
  }

  public async createOrder(webPaymentOrder: WebPaymentOrder): Promise<any> {
    const response = await axios.post<any>(
      `${super.clientsApiBaseUrl()}/webpaymentorder`,
      webPaymentOrder,
    );
    return response.data;
  }

  public async callNexi(serviceUrl: string, payload: NexiPayload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const parsedData = JSON.parse(JSON.stringify(payload));
    const formData = new FormData();
    for (const key in parsedData) {
      if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
        const element = parsedData[key];
        formData.append(key, element);
      }
    }
    await axios.post(serviceUrl, formData, config);
  }

  public getIpnClientsApiEndpoint(urlpost: string): string {
    // return `https://dev2-client.nebular.online/api/v1/${urlpost}`; // TODO use this to test in local
    return `${super.clientsApiBaseUrl()}/${urlpost}`;
  }
}

export const webPaymentService = new WebPaymentApiService();
