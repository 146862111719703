import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import mainRoutes from './modules/main.routes';
import { authMiddlewareRouter } from '@/router/middleware/authMiddlewareRouter';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [...mainRoutes];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

authMiddlewareRouter(router);

export default router;
