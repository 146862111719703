export enum CommonMutations {
  SetAnalyticsConsent = 'setAnalyticsConsent',
  SetLanguage = 'setLanguage',
}

export enum CommonActions {
  SetAnalyticsConsent = 'common/setAnalyticsConsent',
  SetLanguage = 'common/setLanguage',
}

export enum CommonGetters {
  AnalyticsConsent = 'common/analyticsConsent',
  Language = 'common/language',
}
