export enum WebPaymentsMutations {
  SetServices = 'webPaymentsSetServices',
  SetConfigs = 'webPaymentsSetConfigs',
  SetChargeAmount = 'webPaymentsSetChargeAmount',
  SetSelectedServiceType = 'webPaymentsSetSelectedServiceType',
  SetNexiPayload = 'webPaymentsSetNexiPayload',
  IncrementNexiTries = 'webPaymentsIncrementNexiTries',
  ResetNexiTries = 'webPaymentsResetNexiTries',
  Clear = 'clear',
}

export enum WebPaymentsActions {
  GetServices = 'webPayments/getServices',
  GetConfigs = 'webPayments/getConfigs',
  SetChargeAmount = 'webPayments/setChargeAmount',
  SetSelectedServiceType = 'webPayments/setSelectedServiceType',
  SetNexiPayload = 'webPayments/setNexiPayload',
  IncrementNexiTries = 'webPayments/incrementNexiTries',
  ResetNexiTries = 'webPayments/resetNexiTries',
  Clear = 'webPayments/clear',
}

export enum WebPaymentsGetters {
  GetServices = 'webPayments/getServices',
  GetConfigs = 'webPayments/getConfigs',
  GetChargeAmount = 'webPayments/getChargeAmount',
  GetSelectedServiceType = 'webPayments/getSelectedServiceType',
  GetNexiPayload = 'webPayments/getNexiPayload',
  GetNexiTries = 'webPayments/getNexiTries',
}
