import router from '@/router';
import { Routes } from '@/router/routes';
import store from '@/store';
import { eventHub } from '@/store/consts';
import { AuthActions } from '@/store/modules/auth/enums';
import { CommonGetters } from '@/store/modules/common/enums';
import { MediasActions } from '@/store/modules/medias/enums';
import { MerchantConfigActions } from '@/store/modules/merchant-config/enums';
import { RootActions } from '@/store/enums';
import * as msal from '@azure/msal-browser';
import Vue, { PluginObject, VueConstructor } from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $msal: MsalPlugin;
  }
}

export interface MsalPluginOptions {
  clientId: string;
  loginAuthority: string;
  changePasswordAuthority: string;
  knownAuthority: string;
  apiReadScope: string;
}

let msalInstance: msal.PublicClientApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {
  public isLoading = true;

  private pluginOptions: MsalPluginOptions = {
    clientId: '',
    loginAuthority: '',
    changePasswordAuthority: '',
    knownAuthority: '',
    apiReadScope: '',
  };

  private scopes: string[] = [];

  public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
    if (!options) {
      throw new Error('MsalPluginOptions must be specified');
    }

    this.pluginOptions = options;
    this.initialize(options);
    msalPluginInstance = this;
    vue.prototype.$msal = Vue.observable(msalPluginInstance);
  }

  public async signIn(email: string, qrCode: string): Promise<void> {
    store.dispatch(AuthActions.SetAwaitingB2CResponse, true);

    const loginRequest: msal.RedirectRequest = {
      redirectUri: window.location.origin,
      loginHint: email as string,
      scopes: this.scopes,
      state: qrCode,
      extraQueryParameters: {
        ui_locales: store.getters[CommonGetters.Language],
      },
    };

    await msalInstance.loginRedirect(loginRequest);
  }

  public async signOut(): Promise<void> {
    store.dispatch(AuthActions.SetAwaitingB2CResponse, true);
    await msalInstance.logoutRedirect();
  }

  public async acquireToken(): Promise<
    string | false | void | msal.AuthenticationResult
  > {
    const request = {
      redirectUri: window.location.origin,
      account: msalInstance.getAllAccounts()[0],
      scopes: this.scopes,
    };

    try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      //console.log('AcquireTokenSilent error:');
      //console.log(error);
      if (error instanceof msal.InteractionRequiredAuthError) {
        const response = await msalInstance
          .acquireTokenPopup(request)
          .catch((popupError) => {
            console.error('acquireTokenPopup error: ');
            console.error(popupError);
          });
        if (response) {
          return response.accessToken;
        } else {
          this.logout();
        }
      } else {
        this.logout();
      }
      return false;
    }
  }

  public async changePassword(): Promise<void> {
    store.dispatch(AuthActions.SetAwaitingB2CResponse, true);

    const changePasswordRequest: msal.RedirectRequest = {
      redirectUri: window.location.origin,
      authority: this.pluginOptions.changePasswordAuthority,
      scopes: this.scopes,
    };

    await msalInstance.loginRedirect(changePasswordRequest);
  }

  public isUserAuthenticated(): boolean {
    const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  }

  private initialize(options: MsalPluginOptions) {
    // MSAL will supply openid, profile and offline_access by default during login requests.
    this.scopes = [options.apiReadScope];

    const msalConfig: msal.Configuration = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority],
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);
    msalInstance
      .handleRedirectPromise()
      .then((response) => {
        //console.log('RedirectPromise response: ');
        //console.log(response);
        this.isLoading = true;
        if (response != null) {
          store.dispatch(AuthActions.Login).then(() => {
            // console.log('response authenticated');
            router.push({ name: Routes.PreCustomer });
          });
        }
      })
      .catch((error) => {
        //console.log('RedirectPromise error: ');
        //console.log(error);
      })
      .finally(() => {
        if (this.isUserAuthenticated()) eventHub.$emit('authenticated');
        this.isLoading = false;
        store.dispatch(AuthActions.SetAwaitingB2CResponse, false);
      });
  }

  private logout() {
    store.dispatch(RootActions.ShowSpinner);
    store.dispatch(AuthActions.Logout).then(() => {
      store.dispatch(MerchantConfigActions.Clear);
      store.dispatch(MediasActions.Clear);
      store.dispatch(RootActions.RemoveSpinner);
    });
  }
}
