import CommonState from '@/store/modules/common/commonState';
import RootState from '@/store/rootState';
import { GetterTree } from 'vuex';

const getters: GetterTree<CommonState, RootState> = {
  analyticsConsent: (state: CommonState) => {
    return state.analyticsConsent;
  },
  language: (state: CommonState) => {
    return state.language;
  },
};

export default getters;
