import { GetterTree } from 'vuex';
import MediasState from './mediasState';
import RootState from '@/store/rootState';

const getters: GetterTree<MediasState, RootState> = {
  getMedias: (mediasState: MediasState) => mediasState.medias,
  getMerchantId: (mediasState: MediasState) => mediasState.merchantId,
  getMediaStatusToAssociate: (mediasState: MediasState) =>
    mediasState.mediaStatusToAssociate,
  getSelectedMediaId: (mediasState: MediasState) => mediasState.selectedMediaId,
};

export default getters;
