import App from '@/App.vue';
import i18n from '@/i18n';
import { appApplicationErrorsInterceptor } from '@/interceptors/axios-interceptor-app-errors';
import { appInsightRequestErrorsInterceptor } from '@/interceptors/axios-interceptor-app-insight-errors';
import { ConfigurationSpa } from '@/models/common/configurationSpa';
import { MsalPlugin, MsalPluginOptions } from '@/plugin/MsalPlugin';
import { currenciesPlugin } from '@/plugin/CurrenciesPlugin';
import router from '@/router';
import { configurationService } from '@/services/spa-conf/configuration.service';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import { spinnerPlugin } from '@/plugin/SpinnerPlugin';
import { toasterPlugin } from '@/plugin/ToasterPlugin';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueAppInsights from 'vue-application-insights';
import VModal from 'vue-js-modal';
import Loading from 'vue-loading-overlay';
import OTPInput8 from '@8bu/vue-otp-input';
import 'vue-loading-overlay/dist/vue-loading.css';
import Toasted from 'vue-toasted';
import { headerBearerTokenInterceptor } from './interceptors/axios-interceptor-header-token';
import { refreshTokenInterceptor } from './interceptors/axios-interceptor-renew-token';

const initApp = () => {
  const spaConfig: ConfigurationSpa = JSON.parse(
    sessionStorage.getItem('spa.config') || '',
  );

  const msalOptions: MsalPluginOptions = {
    clientId: spaConfig.msalConfig.clientId,
    loginAuthority: spaConfig.msalConfig.loginAuthority,
    changePasswordAuthority: spaConfig.msalConfig.changePasswordAuthority,
    knownAuthority: spaConfig.msalConfig.knownAuthority,
    apiReadScope: spaConfig.msalConfig.apiReadScope,
  };

  Vue.use(new MsalPlugin(), msalOptions);
  Vue.use(currenciesPlugin);

  Vue.config.productionTip = false;
  Vue.use(BootstrapVue);
  Vue.use(Loading);
  Vue.use(OTPInput8);
  Vue.use(Toasted);
  Vue.use(spinnerPlugin);
  Vue.use(toasterPlugin, { i18n });
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_CODE,
    router,
  });
  Vue.use(VueAppInsights, {
    id: spaConfig.instrumentationKeyAppInsight,
    router,
  });
  Vue.use(VModal);

  const app = new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
  }).$mount('#app');

  headerBearerTokenInterceptor();
  refreshTokenInterceptor();
  appInsightRequestErrorsInterceptor(app);
  appApplicationErrorsInterceptor(app);
};

const hasConfig = sessionStorage.getItem('spa.config');

if (!hasConfig) {
  configurationService.getConfig().then((response: ConfigurationSpa) => {
    sessionStorage.setItem('spa.config', JSON.stringify(response));
    initApp();
  });
} else {
  initApp();
}
