import { ActionContext, ActionTree } from 'vuex';
import { webPaymentService } from '@/services/web-payments/webpayment.service';
import { WebPaymentsMutations } from './enums';
import WebPaymentsState from './webPaymentsState';
import RootState from '@/store/rootState';
import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { WebPaymentServiceType } from '@/models/web-payments/webPaymentServiceType';

const actions: ActionTree<WebPaymentsState, RootState> = {
  getServices: (store: ActionContext<WebPaymentsState, RootState>) => {
    return new Promise((resolve, reject) => {
      webPaymentService
        .getServices()
        .then((response) => {
          if (response.length > 0) {
            store.commit(WebPaymentsMutations.SetServices, response);
          }
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  getConfigs: (
    store: ActionContext<WebPaymentsState, RootState>,
    merchantId: string,
  ) => {
    return new Promise((resolve, reject) => {
      webPaymentService
        .getConfigs(merchantId)
        .then((response) => {
          if (response.length > 0) {
            store.commit(WebPaymentsMutations.SetConfigs, response);
          }
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  setChargeAmount: (
    store: ActionContext<WebPaymentsState, RootState>,
    chargeAmount: number,
  ) => {
    store.commit(WebPaymentsMutations.SetChargeAmount, chargeAmount);
  },
  setSelectedServiceType: (
    store: ActionContext<WebPaymentsState, RootState>,
    serviceType: WebPaymentServiceType,
  ) => {
    store.commit(WebPaymentsMutations.SetSelectedServiceType, serviceType);
  },
  setNexiPayload: (
    store: ActionContext<WebPaymentsState, RootState>,
    payload: NexiPayload,
  ) => {
    store.commit(WebPaymentsMutations.SetNexiPayload, payload);
  },
  incrementNexiTries: (store: ActionContext<WebPaymentsState, RootState>) => {
    store.commit(WebPaymentsMutations.IncrementNexiTries);
  },
  resetNexiTries: (store: ActionContext<WebPaymentsState, RootState>) => {
    store.commit(WebPaymentsMutations.ResetNexiTries);
  },
  clear: (store: ActionContext<WebPaymentsState, RootState>) => {
    store.commit(WebPaymentsMutations.Clear);
  },
};

export default actions;
