export enum RootMutations {
  ShowToaster = 'showToaster',
  ShowEternalToaster = 'showEternalToaster',
  RemoveToaster = 'removeToaster',
  RemoveToasters = 'removeToasters',
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
}

export enum RootActions {
  ShowToaster = 'showToaster',
  ShowEternalToaster = 'showEternalToaster',
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
  RemoveToasters = 'removeToasters',
  RemoveToaster = 'removeToaster',
}

export enum RootGetters {
  Toasters = 'toasters',
  Spinners = 'spinners',
}

export enum DashboardActions {
  DashboardView = 0,
  DeleteAccount = 1,
  EditAccount = 2,
  PurchaseHistory = 3,
  Feedback = 4,
  OnlineRecharge = 5,
}
