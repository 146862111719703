import * as cc from 'currency-codes';
import * as ccf from 'currency-formatter/index';
import { Store } from 'vuex';
import RootState from '@/store/rootState';

export class CurrenciesPluginService {
  constructor(private store: Store<RootState>) {}
  public parse(value: number, currencyCode: string): number {
    return this.getNormalizedValue(value, currencyCode);
  }

  public parseToBase(value: number, currencyCode: string): number {
    return this.getBaseValue(value, currencyCode);
  }

  public parseWithCode(value: number, currencyCode: string): string {
    const cu = ccf.findCurrency(currencyCode);
    switch (currencyCode) {
      case 'EUR':
      case 'COP':
      case 'RSD':
      case 'RON':
        cu!.thousandsSeparator = '.';
        break;
      case 'ZAR':
        cu!.thousandsSeparator = ' ';
        break;
      case 'CZK':
        cu!.thousandsSeparator = '.';
        cu!.decimalDigits = 0;
        break;
      case 'CLP':
      case 'UYU':
      case 'PYG':
        cu!.decimalDigits = 0;
        break;
      case 'MXN':
        cu!.thousandsSeparator = ',';
        cu!.decimalDigits = 2;
        break;
      default:
        break;
    }

    const formatted = ccf.format(
      this.getNormalizedValue(value, currencyCode),
      cu,
    );
    return formatted;
  }

  public getNumberOfDecimalsOfSelectedMerchant(currencyCode: string): number {
    let numberOfDigits = cc.code(currencyCode)?.digits;
    if (
      currencyCode === 'CZK' ||
      currencyCode === 'CLP' ||
      currencyCode === 'UYU' ||
      currencyCode === 'PYG'
    ) {
      numberOfDigits = 0;
    }
    return numberOfDigits || 0;
  }

  private getNormalizedValue(value: number, currencyCode: string): number {
    const numberOfDigits =
      this.getNumberOfDecimalsOfSelectedMerchant(currencyCode);
    return value / Math.pow(10, numberOfDigits);
  }

  private getBaseValue(value: number, currencyCode: string): number {
    const numberOfDigits =
      this.getNumberOfDecimalsOfSelectedMerchant(currencyCode);
    const inBaseCurrency = value * Math.pow(10, numberOfDigits);
    const result = Math.round((inBaseCurrency + Number.EPSILON) * 100) / 100;
    return result;
  }
}
