import { ActionContext, ActionTree } from 'vuex';
import { MerchantMigrationMutations } from './enums';
import MerchantMigrationState from './merchantMigrationState';
import RootState from '@/store/rootState';
import { migrationService } from '@/services/migration/migration.service';

const actions: ActionTree<MerchantMigrationState, RootState> = {
  getMigratedMerchants: (
    store: ActionContext<MerchantMigrationState, RootState>,
    merchantId: string,
  ) => {
    return new Promise((resolve, reject) => {
      migrationService
        .getMigratedMerchants(merchantId)
        .then((response) => {
          store.commit(
            MerchantMigrationMutations.SetMigratedMerchants,
            response,
          );
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  clear: (store: ActionContext<MerchantMigrationState, RootState>) => {
    store.commit(MerchantMigrationMutations.Clear);
  },
};

export default actions;
