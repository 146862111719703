import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import actions from '@/store/actions';
import auth from '@/store/modules/auth/auth';
import common from '@/store/modules/common/common';
import medias from '@/store/modules/medias/medias';
import merchantConfig from '@/store/modules/merchant-config/merchantConfig';
import merchantMigration from '@/store/modules/merchant-migration/merchantMigration';
import webPayments from '@/store/modules/web-payments/webPayments';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import state from '@/store/state';
import RootState from '@/store/rootState';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: [
    'auth',
    'medias',
    'merchantConfig',
    'merchantMigration',
    'common',
    'webPayments',
  ],
});

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
    common,
    medias,
    merchantConfig,
    merchantMigration,
    webPayments,
  },
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
