import {
  AuthStatus,
  default as AuthState,
  default as CustomerInfo,
} from '@/store/modules/auth/authState';
import { eventHub } from '@/store/consts';

import { MutationTree } from 'vuex';

const mutations: MutationTree<AuthState> = {
  authRequest: (authState: AuthState) => {
    authState.status = AuthStatus.InProgress;
  },

  authSuccess: (
    authState: AuthState,
    authData: {
      customerInfo: CustomerInfo;
    },
  ) => {
    authState.status = AuthStatus.Succeeded;
    authState.customerInfo = authData.customerInfo;
    eventHub.$emit('gotcustomer', true);
  },

  authError: (authState: AuthState) => {
    authState.status = AuthStatus.Error;
  },

  authLogout: (authState: AuthState) => {
    authState.status = AuthStatus.Unknown;
    authState.customerInfo = undefined;
  },

  authAwaitingB2CResponse: (authState: AuthState, awaiting: boolean) => {
    authState.awaitingB2CResponse = awaiting;
  },
};

export default mutations;
