export class NexiPayload {
  public alias!: string;
  public importo!: number;
  public divisa!: string;
  public codTrans!: string;
  public mac!: string;
  public urlpost!: string;
  public mail!: string;
  public nome!: string;
  public cognome!: string;
  public url!: string;
  public url_back!: string;
  public media_id!: string;
  public numContratto!: string;
}
