import { Media } from '@/models/medias/media';
import { MediaStatus } from '@/models/medias/mediaStatus';
import BaseService from '@/services/base/base.service';
import axios from 'axios';

class MediaService extends BaseService {
  public async getByHash(hash: string): Promise<MediaStatus> {
    const response = await axios.get<MediaStatus>(
      `${super.clientsApiBaseUrl()}/medias/hash/${hash}`,
    );
    return response.data;
  }

  public async getMedias(): Promise<Media[]> {
    const request: MediasRequest = {
      cogesKeyEnabledOnly: true,
    };
    const response = await axios.get<Media[]>(
      `${super.clientsApiBaseUrl()}/medias`,
      { params: request },
    );
    return response.data;
  }

  public async updateMedia(
    mediaId: string,
    merchantId: string,
    newName: string,
  ): Promise<any> {
    const response = await axios.put(
      `${super.clientsApiBaseUrl()}/medias/${mediaId}`,
      { merchantId: merchantId, alias: newName },
    );
    return response;
  }

  public async associateMediaCustomer(hash: string): Promise<any> {
    await axios.post(`${super.clientsApiBaseUrl()}/medias/${hash}/association`);
  }

  public async deleteMediaCustomer(mediaId: string): Promise<any> {
    await axios.delete(
      `${super.clientsApiBaseUrl()}/medias/${mediaId}/association`,
    );
  }
}

export const mediaService = new MediaService();

export interface MediasRequest {
  cogesKeyEnabledOnly: boolean;
}
