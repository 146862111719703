import { ConfigurationSpa } from '@/models/common/configurationSpa';

export default class BaseService {
  protected clientsApiBaseUrl(): string {
    const spaConfig: ConfigurationSpa = JSON.parse(
      sessionStorage.getItem('spa.config') || '',
    );
    return spaConfig.clientsApiUrl;
  }
}
