export default interface CustomerInfo {
  email: string;
  name: string;
  surname: string;
  language: string;
  gender: string;
  dateOfBirth: Date;
  country: string;
  state: string;
  streetAddress: string;
  city: string;
  postalCode: string;
}

export default interface AuthState {
  status: AuthStatus;
  awaitingB2CResponse: boolean;
  customerInfo?: CustomerInfo;
}

export enum AuthStatus {
  Unknown,
  InProgress,
  Succeeded,
  Error,
  Refreshed,
}
